<template>
  <div class="signDetail">
    <p class="tips" v-if="isShowTips"><van-icon name="warning-o" />签约完成，认购资金接收账户如下，请尽快将认购款项转账至该账户</p>
     <!--倒计时-->
   
      <div class="time" v-if="isShowTime">
        <template v-if="detailData.nowTime < detailData.cancelTime || detailData.cancelTime != undefined">
          <i>取消时间：</i>
          <span id="cancelTime">{{cancelTime | formateDate}}</span>
        </template>
      </div>
   
    <!--end倒计时-->
    <div class="signDetailCon" v-if="isShow2">
      <p class="mini-title">分配计划</p>
      <ul>
        <li  @click="paymentPlan()">
          <span>分配计划</span>
          <span><van-icon name="arrow" /></span>
        </li>
      </ul>
    </div> 
    <div class="signDetailCon" v-if="isNoestablish">
      <ul>
        <template v-if="detailData.reserveAmount">
        <li>
          <span>认购金额</span>
          <span><b>{{detailData.reserveAmount}}万元</b></span>
        </li>
        </template>
        <template v-if="detailData.productAccountName">
        <li>
          <span>账户名称</span>
          <span>{{detailData.productAccountName}}</span>
          <span class="blue" @click="seccendCopy(detailData.productAccountName)">复制</span>
        </li>
        </template>
        <template v-if="detailData.productAccount">
        <li>
          <span>账户账号</span>
          <span>{{detailData.productAccount}}</span>
          <span class="blue" @click="seccendCopy(detailData.productAccount)">复制</span>
        </li>
        </template>
        <template v-if="detailData.productAccountBank">
        <li>
          <span>开户行</span>
          <span>{{detailData.productAccountBank}}</span>
          <span class="blue" @click="seccendCopy(detailData.productAccountBank)">复制</span>
        </li>
        </template>
      </ul>
    </div> 
    <div class="signDetailCon" v-if="isEstablish"> 
      <p class="mini-title">认购信息</p>
      <ul>
        <li>
          <span>产品名称</span>
          <span><b>{{detailData.productName}}</b></span>
        </li>
        <li>  
          <span>历史业绩比较基准</span>
          <span>{{detailData.totalRate}}%</span>
        </li>
        <template v-if="detailData.floatRate">
          <li>  
            <span>业绩补贴</span>
            <span>{{detailData.floatRate}}%</span>
          </li>
        </template>
        <template v-if="detailData.productValueDateDesc">
          <li>  
            <span>收益起算日</span>
            <span>{{detailData.productValueDateDesc}}</span>
          </li>
        </template>
        <template v-if="detailData.productDueDateDesc">
          <li>  
            <span>预计到期日</span>
            <span>{{detailData.productDueDateDesc}}</span>
          </li>
        </template>
        <li>  
          <span>认购金额</span>
          <span><b>{{detailData.reserveAmount}}万元</b></span>
        </li>
        <template v-if="detailData.actualDueDate">
          <li>  
            <span>实际到期日</span>
            <span><b>{{detailData.actualDueDate}}</b></span>
          </li>
        </template>
        <template v-if="detailData.actualInterest">
          <li >  
            <span>实际收益</span>
            <span><b>{{detailData.actualInterest}}元</b></span>
          </li>
        </template>
      </ul>
     </div> 
     <div class="signDetailCon">
      <p class="mini-title" v-if="status12">分配账户信息</p>
      <p v-if="status10" class="tips">您签约的银行卡如下，请务必用此账户打款！</p>
      <ul v-if="status11">
        <li>
          <span>账户名称</span>
          <span><b>{{detailData.accountName}}</b></span>
        </li>
        <li>
          <span>开户行</span>
          <span><b>{{detailData.accountBankName}}</b></span>
        </li>
        <li>
          <span>银行账户</span>
          <span><b>{{detailData.account}}</b></span>
        </li>
      </ul>
    </div>  
    <div class="signDetailCon">
      <p class="mini-title">产品说明书及电子合同</p>
      <ul>
        <li @click="getProtocolPreview(9)">
          <span>《产品说明书》</span>
          <span><van-icon name="arrow" /></span>
        </li>
        <li  @click="getProtocolPreview(1)">
          <span>《认购协议》</span>
          <span><van-icon name="arrow" /></span>
        </li>
        <li  @click="getProtocolPreview(3)" v-if="isShowQ">
          <span>《投资者风险测评问卷》</span>
          <span><van-icon name="arrow" /></span>
        </li>
        <li v-if="isShow2"  @click="getProtocolPreview(2)">
          <span>《产品成立公告》</span>
          <span><van-icon name="arrow" /></span>
        </li>
      </ul>
    </div>
    <div class="signButton" @click="upload()" v-if="isUpload">
      <p>上传打款凭证</p>
    </div>
  </div>
</template>
<script>
import $ from 'jquery'
import { Dialog } from 'vant';
import { getAlreadySignDetail,selectProductDetail } from "../services/proApi";
import Clipboard from "clipboard";

export default {
  data() {
    return {
     detailData:{ },
     status10:true,
     status11:true,
     status12:true,
     isUpload:false,
     isShowTips:false,
     isNoestablish:true,
     isEstablish:true,
     isShow2:false,//已成立
     isShow3:false,//已分配=已兑付
     time:'',
     reserveId:'',
     signType:'',
     isShowQ:false,
     isShowTime:false,
     cancelTime:'',
    };
  },
  created: function() {
    var _this = this;
    var reserveId=_this.$route.query.reserveId;
    var signType=_this.$route.query.signType;
    _this.reserveId=reserveId;
    _this.getAlreadySignDetail(reserveId,signType);
    if(signType==0){//签约类型 0：线上，1：线下
    _this.isShowQ=true;
    }
  },
  methods: {
    paymentPlan(){//分配计划
      this.$router.push({
        path: "paymentPlan", query: {reserveId:this.reserveId}
      });
    },
    async getProtocolPreview(type){
      location.href = 'https://api.liangliangsz.tech/api/product/sign/getSignProtocol?reserveId='+this.reserveId+'&typeId='+type+"&sign="+this.detailData.sign
    },
    async getAlreadySignDetail(reserveId,signType){
      const datas =await getAlreadySignDetail({reserveId:reserveId,signType:signType});
        if (datas.code === 1) {
            this.detailData=datas.data;
            var _da = datas.data  
                    
            if(_da.statusDesc=="已签约，待打款"){
              if(_da.cancelTime && this.signType==0){
                  this.isUpload=true;
                  this.isShowTime=true;
                  this.status10=true;
                  this.status11=true;
                  this.isEstablish=false;
                  if(_da.nowTime > _da.cancelTime || _da.cancelTime == undefined){
                      this.isShowTime=false;
                  }else{
                      this.countDownTimeBlock('cancelTime',_da.cancelTime,_da.nowTime)
                  }
              }else{
                 this.cancelTime=_da.cancelTime//线上打款
              }
            }
            if(_da.statusDesc=="已打款，待审核"){
                this.isUpload=true;
                this.isShowTips=false
						    this.isEstablish=false;
                this.status10=true;
                this.status11=true;
            }
            if(_da.statusDesc=="已成立"){
              this.status11=true;
              this.status12=true;
              this.isEstablish=true
              this.isShowTips=false
              this.isNoestablish=false
              this.isShow2=true
            }
            if(_da.statusDesc=="未打款，已取消"){
                this.isShowTips=false
                this.isEstablish=false
              if(_da.cancelTime && _da.cancelTime != undefined){
                  this.isShowTime=true;
                  this.cancelTime=_da.cancelTime
              }
            }
            
            if(_da.statusDesc=="已到账，待成立"){
                 this.isShowTips=false
            }else if(_da.statusDesc=="已审核，待成立"){
                this.isShowTime=false
                this.status10=false;
                this.status12=true
                this.status11=true
            }
            // if(_da.statusDesc=="已兑付"){
            //   this.isShow2=true;
            //   this.isShow3=true;
            // }
        } 
   },
   upload(){
       this.$router.push({
        path: '/upLoadCertificate',
        query: {
          notResidentIDCard: true
        }
      });
   },
   seccendCopy(val) {
     let _this=this;
      _this.$copyText(val).then(
        function(e) {
          _this.$toast('复制成功!');
        },
        function(e) {
          _this.$toast('复制失败！');
        }
      );
    },
    countDownTimeBlock(id,putStartTime,nowTime){
        var sys_second1 = parseInt((putStartTime - nowTime)/1000);
        var timer1 = setInterval(function(){
          if(document.getElementById(id)){
            if (sys_second1 > 1) {
              sys_second1 -= 1;
              var day1 = Math.floor((sys_second1 / 3600) / 24);
              var hour1 = Math.floor((sys_second1 / 3600) % 24);
              var minute1 = Math.floor((sys_second1 / 60) % 60);
              var second1 = Math.floor(sys_second1 % 60);
              var showDay1 = day1 == 0 ? '' : '<span>'+day1+'</span>天 ';
              var showHour1 = hour1<10?"0"+hour1:hour1;//计算小时
              var showMinute1 = minute1<10?"0"+minute1:minute1;//计算分钟
              var showSecond1 = second1<10?"0"+second1:second1;//计算秒杀

              document.getElementById(id).innerHTML= showDay1+'<span>'+showHour1+'</span> : <span>'+showMinute1+'</span> : <span>'+showSecond1+'</span>'
            } else {
                clearInterval(timer1);
            }

          }else{
            clearInterval(timer1);
        }

      }, 1000);
    }
  },
   filters: {
    formateDate: function(now) {
      var now = parseInt(now);
      var d = new Date(now);
      return d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
    },
  }
};
</script>

<style scoped lang="scss">
.signDetail{
  padding-bottom: 4rem;
  .mini-title {
      padding-left: 0.5rem;
      border-left: 0.3rem solid #356BFF;
      font-size: 1rem;
      margin: 13px;
  }
  .tips{line-height:1rem;color:#356BFF;padding:1rem 1rem 0 1rem;}
  .time{
    color:red;text-align:center;padding:1rem 0;
    .van-count-down{display: inline-block;color:red;}
    .block{background: none;}
  }
  .signDetailCon{
    .tips{text-align: center;color:red;padding:1rem;}
    li{
       span:last-child{color:#666}
       b{color:#356BFF}
       span.blue{color:#356BFF}
    }
  }
    ul{
      background: #fff;padding:0 1rem;
      li{
        height:4rem;
        line-height:4rem;
        font-size:0.9rem;
        display: flex;justify-content: space-between;
        border-bottom:0.01rem solid #e9e9e9;
      }
    }
  
  .book{
    margin-top:1rem;color:#333;
  }
  .signButton{
    p{
      position: fixed;
      bottom: 0;
      width: 100%;
      height:3rem;
      line-height:3rem;
      background: -webkit-linear-gradient(left,#0398FF,#356BFF);
      font-size: 0.9rem;
      color:#fff;
      text-align: center;
      }
  }
}
</style>
